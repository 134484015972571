<template>
    <div class="businessDailyReport">
        <div class="filter-box">
            <div class="filter-bnts ">
                <div class="bnt verticalCenter" :class="{selected:queryType==false}" @click="nowadays()" >
                    <div class="title">
                        <span>今天 <br/>({{userInfo.Rpt_Date}})</span>
                    </div>
                </div>
                <div class="bnt verticalCenter" :class="{selected:queryType==true}" @click="queryType=true" v-if="ReportDataFromDays>=0">
                    <div class="title">
                        <span>按日期范围</span>
                    </div>
                </div>
                <div class="inline-block" v-if="ReportDataFromDays>=0">
                    <el-date-picker class="from-date"
                        v-model="businessHours"
                        type="daterange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :default-time="defaultTime"
                        @change="change"
                        :disabled='queryType==false'
                        :disabled-date="disabledDate"
                    > </el-date-picker>
                </div>
                <div class="bnt-search" @click="GetDayBusinnesAnalysisPoints()" >查询</div>
            </div>
            <div class="right-bnts">
                <div class="bnt" @click="exportExcel()"><i class="iconfont icon-daochu"></i>导出报表</div>
                <div class="bnt" @click="print()"><i class="iconfont icon-dayin"></i>打印报表</div>
            </div>
        </div>
        <div class="content-box clearfix">
            <div class="left-table">
                <div class="title"><i class="iconfont icon-tongji"></i>统计维度</div>
                <div class="select-box clearfix" v-mouse-scroll>
                    <!--selected-->
                    <div class="tag-li" :class="{selected:item.isType==true,'li-filter':item.AnalysisPoint_Type==2}" v-for="item in tableList" :key="item" @click="DayBusinnesClick(item)">
                        <div class="text">
                            <i class="icon iconfont" :class="weiDuIcon[item.AnalysisPoint_ID]"></i>
                            <span class="inlineNowrap2 name"> {{item.AnalysisPoint_Name}}</span>
                            <i class="iconfont icon-gou-fine"></i>
                        </div>
                        <div class="filter-bnt" v-if="item.AnalysisPoint_Type==2" @click.stop="filterClick(item)">
                            <i class="iconfont icon-shaixuan"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-view">
                <div class="printExample" >
                    <div class="scroll-box" v-scroll-anime="{up:'page-up',down:'page-down'}" v-mouse-scroll>
                        <div v-html="Print_Html"></div>
                    </div>
                </div>
                <div class="page-bnt clearfix">
                    <div class="bnt page-up" ref="page-up"><i class="iconfont icon-fangxiang-up"></i></div>
                    <div class="bnt page-down" ref="page-down"><i class="iconfont icon-fangxiang-down"></i></div>
                </div>
            </div>
        </div>
        <!--权限控制-->
        <modal-load :isShow="checkShow">
            <check-user-button-model :isShow="checkShow" v-on:closeModel="checkShow=false" :checkBtnlist="checkBtnlist" @checkbtnReturn="checkbtnReturn" ></check-user-button-model>
        </modal-load>
        <!-- 自定义条件 -->
        <modal-load :isShow="filterShow" isUnload>
            <filter-model :isShow="filterShow" @close="filterShow=false" 
            :data="filterAnalysis.filter"
            :filter="filterAnalysis.BusinnesAnalysisFilters"
            @confirm="filterConfirm"></filter-model>
        </modal-load>
     </div>
</template>

<script>
import { newGuid,printTrJson,printJson,printView } from '../../../../../common'
import XLSX from 'xlsx'
import checkUserButtonModel from '../../../checkUserRightModel/checkUserButtonModel.vue'
import filterModel from './filterModel/filterModel.vue'
/**营业日报 */
export default {
    components:{checkUserButtonModel,filterModel},
    name:'businessDailyReport',
    data(){
        return {
            //分析维度 
            analysisPoints:[],
            //筛选 点击 维度数据
            filterAnalysis:null,
            /**表格数据 */
            tableList:[],
            /**默认日期范围 */
            defaultTime:[new Date(),new Date()],
            /**选中日期范围 */
            businessHours:[new Date(),new Date()],
            /**true: 日期范围    false：当前营业数据*/
            queryType:false,
            userInfo:'',
            dayBusinnesList:[],
            /**打印机名称 */
            Printer_Name:'',
            /**打印机类型  */
            Printer_Type:'',
            //自定义条件 弹层
            filterShow:false,
            checkShow:false,
            /**权限验证临时保存ID和操作员 */
            tempUserId:'',
            tempOperatorName:'',
            checkBtnlist:{},
        }
    },
    computed:{
        //限制门店用户查看几天以内的报表, -1:不能查看历史数据，0:不限制，大于0：限制查看几天内的报表
        ReportDataFromDays(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserViewReportDataFromDays||0
        },
        //维度标签图标
        weiDuIcon(){
            return {
                1:"icon-yingyeqingkuang",2:'icon-fukuanfangshi',3:"icon-dingdanqudao",
                4:"icon-shiduan",5:"icon-caipindalei",6:"icon-caipinbumen",
                7:"icon-a-leimupinleifenleileibie",8:"icon-caipinxiaolei",9:"icon-tuicai",
                10:"icon-zengsong",11:"icon-gaijia",12:"icon-shuliangbiandongtongji",
                13:"icon-yingyee",14:"icon-tongji",15:"icon-jixiao",16:"icon-navicon-cpxstj",
                17:"icon-taocanxiaoshoubaobiao",18:"icon-zhuotai1",19:"icon-zhekou",20:"icon-tuicai",
                21:"icon-xiugai",22:"icon-tuicai"
            }
        },
        /** 打印数据展示 html **/
        Print_Html(){
            return printView(this.PrintBodies);//printView(this.shiftContents);
        },
        /** 打印数据 json **/
        PrintBodies(){
            let printData=printJson("营业日报",{DataItem_FontSize:11,DataItem_FontAlign:1});
            let Rpt_Date=new Date(this.userInfo.Rpt_Date).Format("yyyy/MM/dd")
            if(this.queryType){
                Rpt_Date=new Date(this.businessHours[0]).Format("yyyy/MM/dd")+"-"+new Date(this.businessHours[1]).Format("yyyy/MM/dd");
            }
           
            printData=printData.concat(printJson("交易门店："+this.userInfo.Department_Name));
            printData=printData.concat(printJson("报表日期："+Rpt_Date));

            printData.push(printJson('',{baseline:'-'}));
            printData.push(printJson('',{baseline:'-'}));

            (this.tableList||[]).map((item)=>{
                if(item.isType){
                    printData=printData.concat(printJson("【"+item.AnalysisPoint_Name+"】",{DataItem_FontSize:11}));
                    let dataList=item.DayBusinessList||[];
                    if(dataList.length>0){
                        if(item.AnalysisPoint_Type==1){
                            if(item.DayBusinessListTotal){
                                dataList=dataList.concat(item.DayBusinessListTotal);
                            }
                        }else if(item.AnalysisPoint_Type==2){
                            dataList=this.getFilterData(item,dataList);
                        }
                    }
                    printData=printData.concat(printTrJson(item.BusinnesAnalysisItems,dataList,item.AnalysisPoint_Type));
                    printData.push(printJson('',{baseline:'-'}));
                }
            });

            printData=printData.concat(printJson("打印时间："+new Date().Format("yyyy-MM-dd hh:mm:ss"),{DataItem_FontSize:11}));
            printData=printData.concat(printJson("打印人："+this.userInfo.Login_Name,{DataItem_FontSize:11}));

            let text=[];
            printData.map((item,index)=>{
                item.Print_Line= index+1;
                text.push(item.Print_Text)
            })
            //console.log(printData.map(it=>it.Print_Text).join('\r\n'))
            return printData;
        }
    },
    mounted(){
        this.$nextTick(()=> {
            this.userInfo = this.$auth.getUserInfo();
            this.tempUserId='',this.tempOperatorName=''
            this.nowadays();
            this.GetDayBusinnesAnalysisPoints()
        })
    },
    methods:{
        disabledDate(d){
            if(this.ReportDataFromDays>0 && this.userInfo.Rpt_Date){
                const oneDay =  24 * 3600 * 1000;
                const day = this.ReportDataFromDays * oneDay;
                let maxDate=new Date(this.userInfo.Rpt_Date).getTime()-oneDay;
                let minDate=maxDate-day;
                if(minDate>=d.getTime() || maxDate<d.getTime()){
                    return true;
                }
            }
            return false;
        },
        //根据条件筛选 数据
        getFilterData(item,dataList){
            let list=[];
            if(item.filter){
                if(item.filter.filter?.length>0){//筛选
                    let filter=item.filter.filter;
                    dataList=dataList.filter(it=>!filter.some(f=>{
                        if(Array.isArray(f.value)){
                            return f.value.indexOf(it[f.key])<0
                        }else{
                            return f.value!=it[f.key];
                        }
                    }));
                }

                if(item.filter.order){//排序
                    dataList.order(item.filter.order.key,item.filter.order.Is_Desc?"desc":"asc")
                }
                if(item.filter.group && dataList.length>0){//分组
                    let key=item.filter.group;//分组 key
                    let totalKey={};//需要 聚合的数据
                    let nameKey="";//第一个 用于显示名字 及 分组后 聚合的 key
                    if(item.filter.order && typeof dataList[0][item.filter.order.key]=="number"){
                        totalKey[item.filter.order.key]=0;//必须是数字类型才能合计
                    }

                    
                    item.BusinnesAnalysisItems.forEach((it,index)=>{
                        if(index==0){
                            nameKey=it.DataItem_Key;
                        }else if(typeof dataList[0][it.DataItem_Key] =="number" && it.DataItem_Key!="EAT_SETPRICE"){
                            totalKey[it.DataItem_Key]=0;
                        }
                    })

                    if(item.AnalysisPoint_ID==14 || item.AnalysisPoint_ID==15 || item.AnalysisPoint_ID==18){
                        totalKey["ROOM_CHECKMONEY"]=0;// 结账金额
                        totalKey["ROOM_FACTMONEY"]=0;//实收金额
                    }
                    //汇总的排序规则 默认顺序
                    let groupOrderType="asc";
                    //当 汇总 字段 与 排序字段相同时 汇总排序规则按 排序规则来
                    if(item.filter.order && key=="GROUP_"+(item.filter.order.key||"").replace(/^ORDER_/,"")){
                        groupOrderType=item.filter.order.Is_Desc?"desc":"asc";
                    }

                    dataList.groupBy(key+"_CODE",(tArr)=>{
                        let groupArr=[];

                        tArr.groupBy(nameKey,(it)=>{
                            let json= it.sum(totalKey,2);
                            let isBo=false;//是否所有合计数据都为0
                            for(let k in json){
                                if(json[k]!=0){//
                                    isBo=true;
                                }
                            }
                            if(isBo){
                                groupArr.push(Object.assign({},it[0],json));
                            }
                        })
                        if(item.filter.order){//排序
                            groupArr.order(item.filter.order.key,item.filter.order.Is_Desc?"desc":"asc")
                        }
                        list=list.concat(groupArr);
                        if(key!="GROUP_"+nameKey){
                            let totalJson=tArr.sum(totalKey,2);
                            let text=tArr[0][key]||"";
                            totalJson[nameKey]=text.indexOf("小计")>=0?text:(text+"小计");
                            totalJson.isTotal=true;//小计
                            //小计
                            list.push(totalJson);
                            
                        }
                    },groupOrderType)
                    //合计
                    let total=dataList.sum(totalKey,2);
                    if(item.AnalysisPoint_ID==3){
                        total["FACT_PRE"]=100;//总计占比
                    }
                    total[nameKey]="总计";
                    total.isTotal=true;//小计
                    list.push(total);    
                    if(item.AnalysisPoint_ID==14){
                        list.forEach(it=>{
                            //折后人均%= 结账金额/客流
                            it["ROOM_PREMAN"]= it["ROOM_MANNUM"]==0?0: Number((it["ROOM_CHECKMONEY"]/it["ROOM_MANNUM"]).toFixed(2));
                            //实收人均%=实收金额/客流
                            it["ROOMFACT_PREMAN"]=it["ROOM_MANNUM"]==0?0:Number((it["ROOM_FACTMONEY"]/it["ROOM_MANNUM"]).toFixed(2));
                        })
                    }else if(item.AnalysisPoint_ID==15){
                        list.forEach(it=>{
                            //折后单均%= 结账金额/单数
                            it["ROOM_PREORDER"]= it["ROOM_ORDERNUM"]==0?0:Number((it["ROOM_CHECKMONEY"]/it["ROOM_ORDERNUM"]).toFixed(2));
                            //实收单均%=实收金额/单数
                            it["ROOMFACT_PREORDER"]=it["ROOM_ORDERNUM"]==0?0:Number((it["ROOM_FACTMONEY"]/it["ROOM_ORDERNUM"]).toFixed(2));
                        })
                    }else if(item.AnalysisPoint_ID==18){
                        list.forEach(it=>{
                            //折后桌均%= 结账金额/桌数
                            it["ROOM_PREDESKORDER"]=it["ROOM_ORDERDESKNUM"]==0?0: Number((it["ROOM_CHECKMONEY"]/it["ROOM_ORDERDESKNUM"]).toFixed(2));
                            //实收桌均%=实收金额/桌数
                            it["ROOMFACT_PREDESKORDER"]=it["ROOM_ORDERDESKNUM"]==0?0:Number((it["ROOM_FACTMONEY"]/it["ROOM_ORDERDESKNUM"]).toFixed(2));
                        })
                    }else if(item.AnalysisPoint_ID==21){
                        list.forEach(it=>{
                            //均价%=金额/数量
                            it["EAT_AVGPRICE"]=it["EAT_NUMBER"]==0?0:Number((it["EAT_PRICE"]/it["EAT_NUMBER"]).toFixed(2));
                        })
                    }                     
                }
            }
            return list;
        },
        /**导出数据 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            var newWorkbook = XLSX.utils.book_new();

            this.tableList?.filter(item=>item.isType).forEach(item=>{
                let turnover=[];
                let dataItems=item.BusinnesAnalysisItems.filter(it=>it.DataItem_PrintLen>0);
                
                if(item.AnalysisPoint_Type==0){//行转列导出
                    turnover.push(dataItems.map(bus=>bus.DataItem_Name));
                    turnover.push(dataItems.map(it=>item.DayBusinessList[it.DataItem_Key]))
                    
                }else if(item.AnalysisPoint_Type==1 || item.AnalysisPoint_Type==2){//列表
                    
                    turnover.push(dataItems.map(bus=>bus.DataItem_Name));
                    if(item.DayBusinessList){
                      

                        if(item.AnalysisPoint_Type==2){
                            let dataList=this.getFilterData(item,item.DayBusinessList||[]);
                            dataList?.forEach(day=>{
                                turnover.push(dataItems.map(it=>day[it.DataItem_Key]))
                            })
                        }else{
                            item.DayBusinessList?.forEach(day=>{
                                turnover.push(dataItems.map(it=>day[it.DataItem_Key]))
                            })
                            item.DayBusinessListTotal?.forEach(day=>{
                                turnover.push(dataItems.map(it=>day[it.DataItem_Key]))
                            })
                        }
                        
                    }
                    
                }
                let wb = XLSX.utils.aoa_to_sheet(turnover);
                XLSX.utils.book_append_sheet(newWorkbook,wb,item.AnalysisPoint_Name);
            })
            XLSX.writeFile(newWorkbook,"营业日报.xlsx")
        },
        /**今日 */
        nowadays(){
            this.queryType=false
            let Rpt_Date=new Date(this.userInfo.Rpt_Date);
            Rpt_Date.setDate(Rpt_Date.getDate()-1);
            this.businessHours=[Rpt_Date.Format("yyyy-MM-dd"),Rpt_Date.Format("yyyy-MM-dd")];
        },
        change(){
            if(this.queryType==false){
                this.queryType=true
            }
        },
        /**获取营业日报分析维度 */
        GetDayBusinnesAnalysisPoints(){
            if( this.tableList &&  this.tableList.length>0){
                this.GetDayBusinessReport()
                return;
            }
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            if(this.tempUserId!='' && this.tempOperatorName!=''){
                param.User_ID = this.tempUserId
                param.Operator_Name = this.Operator_Name
            }
            this.$httpAES.post("Bestech.CloudPos.GetDayBusinnesAnalysisPoints",param,false).then((d)=>{
                console.log('获取营业日报分析维度:',d)
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.analysisPoints = d.ResponseBody;
                    this.analysisPoints?.forEach(item=>{
                        item.isType=false;
                        if(item.AnalysisPoint_Type==2){
                            item.filter={}
                            item.BusinnesAnalysisFilters?.forEach((it)=>{
                                if(it.DataFilter_Type==2 && it.Is_Group){ //默认分组
                                    item.filter.group=it.DataFilter_Key;
                                }else if(it.DataFilter_Type==3 && it.Is_Orderby){ //默认排序
                                    item.filter.order={
                                        key:it.DataFilter_Key,
                                        Is_Desc:it.Is_Desc
                                    };
                                }else if(it.DataFilter_Type==1 && it.DataFilter_Key=="FILTER_EATCUSTOMMAS"){
                                    //默认 false- 不包括自定义做法
                                    item.filter.filter=[{
                                        key:"FILTER_EATCUSTOMMAS",
                                        value:false
                                    }];
                                }
                            })
                        }
                    })
                    this.tableList=this.analysisPoints ;
                    this.GetDayBusinessReport()
                }else if(d.ResponseHeader.ResultCode==3003){
                    /**权限不足,去验证保存权限 */
                    this.checkBtnlist = Object.assign(this.checkBtnlist,{ProjectItem_Key:d.ResponseHeader.Msg_Reserve})
                    this.checkShow = true;
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error(e.message);
                console.log(e);
            })
        },
        /**营业日报 */
        GetDayBusinessReport(){
            //this.tableList = this.analysisPoints;
            let time = JSON.parse(JSON.stringify(this.businessHours));
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                DateRange_YN:this.queryType,//是否日期范围查询  true: 日期范围    false：当前营业数据
                Begin_Date:new Date(time[0]).Format("yyyy-MM-dd"),//开始日期
                End_Date:new Date(time[1]).Format("yyyy-MM-dd"), //结束日期
                IsHideMarkedBills:this.$global.isLableData, //是否隐藏带标签的订单
                AnalysisPoints:this.analysisPoints.map(it=>it.AnalysisPoint_ID),//维度
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            if(this.tempUserId!='' && this.tempOperatorName!=''){
                param.User_ID = this.tempUserId
                param.Operator_Name = this.Operator_Name
            }
            this.$httpAES.requestConfig({
                timeout:60*1000//设置1分钟 超时时间
            }).post("Bestech.CloudPos.DayBusinessReport",param).then((d)=>{
                console.log('获取营业日报:',d)
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    console.log('tableList:',this.tableList)
                    this.tableList?.forEach(item=>{
                        if(item.AnalysisPoint_ID==1){
                            if(d.ResponseBody.BusinessOverview){
                                Object.assign(item,{DayBusinessList:d.ResponseBody.BusinessOverview})//营业情况
                            }
                        }
                        else if(item.AnalysisPoint_ID==2){
                            if(d.ResponseBody.BusinessPayments){
                                if(this.$global.isLableData){
                                    let hidePaymentNames=this.$cacheData.get("hidePaymentNames");
                                    if(hidePaymentNames?.length>0){
                                        d.ResponseBody.BusinessPayments=d.ResponseBody.BusinessPayments.filter(it=>hidePaymentNames.indexOf(it.PAY_NAME)<0);
                                    }
                                }
                                Object.assign(item,{DayBusinessList:d.ResponseBody.BusinessPayments,DayBusinessListTotal:[d.ResponseBody.BusinessPaymentsTotal]})//付款方式
                            }
                        }
                        else if(item.AnalysisPoint_ID==3){
                            if(d.ResponseBody.BusinessOrderChannels){
                                Object.assign(item,{DayBusinessList:d.ResponseBody.BusinessOrderChannels,DayBusinessListTotal:[d.ResponseBody.BusinessOrderChannelsTotal]})//订单渠道
                            }
                        }
                        else if(item.AnalysisPoint_ID==4){
                            if(d.ResponseBody.BusinessPhaseSales){
                                Object.assign(item,{DayBusinessList:d.ResponseBody.BusinessPhaseSales,DayBusinessListTotal:[d.ResponseBody.BusinessPhaseSalesTotal]})//时段销售
                            }
                        }
                        else if(item.AnalysisPoint_ID==5){
                            if(d.ResponseBody.BusinessFoodMainCategorys){
                                Object.assign(item,{DayBusinessList:d.ResponseBody.BusinessFoodMainCategorys,DayBusinessListTotal:[d.ResponseBody.BusinessFoodMainCategorysTotal]})//菜品大类统计
                            }
                        }
                        else if(item.AnalysisPoint_ID==6){
                            if(d.ResponseBody.BusinessFoodSubCategorys){
                                Object.assign(item,{DayBusinessList:d.ResponseBody.BusinessFoodSubCategorys,DayBusinessListTotal:[d.ResponseBody.BusinessFoodSubCategorysTotal]})//菜品小类统计
                            }
                        }
                        else if(item.AnalysisPoint_ID==7){
                            if(d.ResponseBody.BusinessFoodMainDeparts){
                                Object.assign(item,{DayBusinessList:d.ResponseBody.BusinessFoodMainDeparts,DayBusinessListTotal:[d.ResponseBody.BusinessFoodMainDepartsTotal]})//一级部门统计
                            }
                        }
                        else if(item.AnalysisPoint_ID==8){
                            if(d.ResponseBody.BusinessFoodSubDeparts){
                                Object.assign(item,{DayBusinessList:d.ResponseBody.BusinessFoodSubDeparts,DayBusinessListTotal:[d.ResponseBody.BusinessFoodSubDepartsTotal]})//二级部门统计
                            }
                        }
                        else if(item.AnalysisPoint_ID==9){
                            if(d.ResponseBody.BusinessFoodRefunds){
                                Object.assign(item,{DayBusinessList:d.ResponseBody.BusinessFoodRefunds,DayBusinessListTotal:[d.ResponseBody.BusinessFoodRefundsTotal]})//退菜统计
                            }
                        }
                        else if(item.AnalysisPoint_ID==10){
                            if(d.ResponseBody.BusinessFoodFrees){
                                Object.assign(item,{DayBusinessList:d.ResponseBody.BusinessFoodFrees,DayBusinessListTotal:[d.ResponseBody.BusinessFoodFreesTotal]})//赠菜统计
                            }
                        }
                        else if(item.AnalysisPoint_ID==11){
                            if(d.ResponseBody.BusinessFoodPriceModifys){
                                Object.assign(item,{DayBusinessList:d.ResponseBody.BusinessFoodPriceModifys})//改价统计
                            }
                        }
                        else if(item.AnalysisPoint_ID==12){
                            if(d.ResponseBody.BusinessFoodNumModifys){
                                Object.assign(item,{DayBusinessList:d.ResponseBody.BusinessFoodNumModifys})//改量统计
                            }
                        }
                        else if(item.AnalysisPoint_ID==13){
                            if(d.ResponseBody.BusinessRoomSales){
                                Object.assign(item,{DayBusinessList:d.ResponseBody.BusinessRoomSales,DayBusinessListTotal:[d.ResponseBody.BusinessRoomSalesTotal]}) //餐厅区域收入
                            }
                        }
                        else if(item.AnalysisPoint_ID==14){
                            if(d.ResponseBody.BusinessRoomHumanFlows){
                                Object.assign(item,{DayBusinessList:d.ResponseBody.BusinessRoomHumanFlows,DayBusinessListTotal:[d.ResponseBody.BusinessRoomHumanFlowsTotal]}) //餐厅区域客流
                            }
                        }
                        else if(item.AnalysisPoint_ID==15){
                            if(d.ResponseBody.BusinessRoomBills){
                                Object.assign(item,{DayBusinessList:d.ResponseBody.BusinessRoomBills,DayBusinessListTotal:[d.ResponseBody.BusinessRoomBillsTotal]}) //餐厅区域单数
                            }
                        }else if(item.AnalysisPoint_ID==16){
                            if(d.ResponseBody.BusinessFoodBills){
                                Object.assign(item,{DayBusinessList:d.ResponseBody.BusinessFoodBills}) //菜品销量原明细
                            }
                        }else if(item.AnalysisPoint_ID==17){
                            if(d.ResponseBody.BusinessTCFoodBills){
                                Object.assign(item,{DayBusinessList:d.ResponseBody.BusinessTCFoodBills}) //套餐菜品销量原明细
                            }
                        }else if(item.AnalysisPoint_ID==18){
                            if(d.ResponseBody.BusinessRoomDeskNumBills){
                                Object.assign(item,{DayBusinessList:d.ResponseBody.BusinessRoomDeskNumBills,DayBusinessListTotal:[d.ResponseBody.BusinessRoomDeskNumBillsTotal]}) //餐厅区域桌数
                            }
                        }else if(item.AnalysisPoint_ID==19){
                            if(d.ResponseBody.BusinessZKConsumeBills){
                                Object.assign(item,{DayBusinessList:d.ResponseBody.BusinessZKConsumeBills,DayBusinessListTotal:[d.ResponseBody.BusinessZKConsumeBillsTotal]}) //折扣明细
                            }
                        }else if(item.AnalysisPoint_ID==20){
                            if(d.ResponseBody.BusinessFoodRefundDetails){
                                Object.assign(item,{DayBusinessList:d.ResponseBody.BusinessFoodRefundDetails,DayBusinessListTotal:[d.ResponseBody.BusinessFoodRefundDetailsTotal]}) //退菜明细
                            }
                        }else if(item.AnalysisPoint_ID==21){
                            if(d.ResponseBody.BusinessFoodRefundDetails){
                                Object.assign(item,{DayBusinessList:d.ResponseBody.BusinessXfMasBills}) //折扣明细
                            }
                        }else if(item.AnalysisPoint_ID==22){//退出原因统计
                            if(d.ResponseBody.BusinessRefundReasonBills){
                                Object.assign(item,{DayBusinessList:d.ResponseBody.BusinessRefundReasonBills,DayBusinessListTotal:[d.ResponseBody.BusinessRefundReasonBillsTotal]}) 
                            }
                        }
                    })
                    
                    
                    if(d.ResponseBody.Printer){//打印设备
                        this.Printer_Name=d.ResponseBody.Printer.Printer_Name;
                        this.Printer_Type=d.ResponseBody.Printer.Printer_Type
                    }
                    console.log('tableList:',this.tableList)
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error(e.message);
                console.log(e);
            })
        },
        DayBusinnesClick(item){
            if(item.isType==true){
                item.isType=false
            }else{
                item.isType = true
            }
        },
        //筛选条件
        filterClick(item){
            this.filterShow=true;
            this.filterAnalysis=item;
        },
        //筛选条件 点击查询
        filterConfirm(data){
            this.filterAnalysis.filter=data;
            this.filterAnalysis.isType = true
            this.filterShow=false;
        },
        /**打印 */
        print(){
            if(this.Printer_Name && this.Printer_Type>=0){
                let printJson=[ {
                    PrintBodies:this.PrintBodies,
                    "Print_Num":1,
                    "Print_Serial": "",
                    "Print_TemplateID": "",
                    "Printer_Name": this.Printer_Name,
                    "Printer_Type": this.Printer_Type,
                }];
                this.$webBrowser.posPrint(printJson);
                this.$message.success("已发送打印指令");
            }else{
                this.$message.success("未获取到打印设备信息");
            }
        },
        /**权限验证返回 */
        checkbtnReturn(date){
            this.checkShow = false;
            this.tempUserId = date.User_ID
            this.tempOperatorName = date.Login_Name
            this.GetDayBusinnesAnalysisPoints()
        },
    }
}
</script>

<style lang="scss">
@import './businessDailyReport.scss'
</style>